import React from 'react'
import Navbar from './Navbar'
import './home.css'
import { NavLink } from 'react-router-dom'
import { FaChevronRight } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import Footer from './Footer';
const Home = () => {
  return (
  <>
    <div className="main">
      <Navbar />
        <main className='main' style={{overflowX: "hidden"}}>
            <section className='foreign_exchange'>
                <div className="container container_p">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-4">
                            <h1 className='heading_h1 fw-bold'>
                              Crypto Future trade <span className="primary_cl"> also known as </span>
                              foreign exchange
                            </h1>
                            <p className="pt-3 text-muted primary_para">
                                currency trading, refers to the buying and selling of different currencies against each other with the aim of making a profit.
                            </p>
                             
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-4">
                            <img src="https://future-trade.io/vendor/images/banner_img03.png" alt="" className='img-fluid' />
                        </div>

                    </div>
                </div>
            </section>
            <section className='future-trade' data-aos="fade-left">
                <div className="container container_p">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-4 d-flex justify-content-center align-items-center">
                            <img src="https://future-trade.io/vendor/images/about01.png" alt="" className='img-fluid' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-4">
                            <h1 className='heading_h1 fw-bold'>About Future-trade
                        </h1>
                            <p className="pt-3 text-muted primary_para">Crypto Future trading, also known as foreign exchange trading or currency trading, involves the buying and selling of currency pairs in the foreign exchange market with the aim of making a profit. Here are some key aspects of Crypto Future trading.Banks
                                and Financial Institutions: They engage in Crypto Future trading for various purposes, including facilitating international trade and managing currency exposure.</p>
                            <p className="pt-3 text-muted primary_para">Currencies are traded in pairs, where one currency is exchanged for another. For example, in the pair EUR/USD, the euro is the base currency, and the U.S. dollar is the quote currency. Major pairs involve the most traded currencies
                                (e.g., EUR/USD, USD/JPY). Minor pairs involve currencies from smaller economies. Exotic pairs involve one major currency and one currency from a developing economy. .
                            </p>
                        </div>

                    </div>


                </div>
            </section>

            <section className='w_ft' data-aos="flip-up">
                <div className="container">
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <h1 className="fw-bold heading_h1">What is Future-trade</h1>
                        <p className="pt-2 text-muted primary_para">Here's a brief overview of how Crypto Future trading works:</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-4">
                            <h1>We’ve built a platform to buy and sell shares.
                            </h1>
                            <div className="timeline mt-3">
                                <div className="timeline_box mt-4">
                                    <h3>Currency pairs</h3>
                                    <div className="pt-2 text-muted">In Crypto Future trading, currencies are always quoted in pairs, such as EUR/USD (Euro/US Dollar), GBP/JPY (British Pound/Japanese Yen), etc</div>
                                </div>
                                <div className="timeline_box mt-4">
                                    <h3>Bid and Ask prices</h3>
                                    <div className="pt-2 text-muted">The bid price is the price at which traders can sell the base currency, and the ask price is the price at which traders can buy the base currency.</div>
                                </div>
                                <div className="timeline_box mt-4">
                                    <h3>Going long and going short
                                    </h3>
                                    <div className="pt-2 text-muted">Traders have the option to either buy (go long) a currency pair if they believe its value will rise or sell (go short) a currency pair if they think its value will fall.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-4">
                            <img src="https://future-trade.io/vendor/images/banner_img.png" alt="" className='img-fluid img_scale' />
                        </div>

                    </div>


                </div>
            </section>

            <section className='w_ft' data-aos="flip-left">
                <div className="container">
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <h1><span className='primary_cl' style={{fontSize: "20px"}}>Future-trade Feature
                        </span></h1>
                        <h1 className="pt-2 heading_h1 fw-bold">Best Features </h1>
                        <p className="pt-2 text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.

                        </p>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card_icn">
                                        <img src="https://future-trade.io/vendor/images/feature-icon-1.png" alt="" className='img-fluid' />
                                    </div>
                                    <div className="card_content mt-2">
                                        <h5 className='pt-3'>Currency Wallet</h5>
                                        <p className='text-muted mt-3'>Popular with Crypto Future traders around the world, the Skrill account is ideal when you need to move money fast across Crypto Future platforms worldwide.</p>
                                    </div>
                                </div>
                                <div className="card_hover_div">
                                    Popular with Crypto Future traders around the world, the Skrill account is ideal when you need to move money fast across Crypto Future platforms worldwide. </div>
                            </div>

                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card_icn">
                                        <img src="https://future-trade.io/vendor/images/feature-icon-2.png" alt="" className='img-fluid' />
                                    </div>
                                    <div className="card_content mt-2">
                                        <h5 className='pt-3'>Internal Integrition</h5>
                                        <p className='text-muted mt-3'>An internal market structure break is simply when there is a minor break in structure in between the major swing highs and lows, indicating a shift in orderflow.</p>
                                    </div>
                                </div>
                                <div className="card_hover_div">
                                    An internal market structure break is simply when there is a minor break in structure in between the major swing highs and lows, indicating a shift in orderflow.
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card_icn">
                                        <img src="https://future-trade.io/vendor/images/feature-icon-3.png" alt="" className='img-fluid' />
                                    </div>
                                    <div className="card_content mt-2">
                                        <h5 className='pt-3'> Security Storage</h5>
                                        <p className='text-muted mt-3'>Crypto Future trading, the common name for the foreign exchange market, entails trading currencies instead of securities (think the U.S. dollar, European euro or Japanese yen).</p>
                                    </div>
                                </div>
                                <div className="card_hover_div">
                                    Crypto Future trading, the common name for the foreign exchange market, entails trading currencies instead of securities (think the U.S. dollar, European euro or Japanese yen).
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card_icn">
                                        <img src="https://future-trade.io/vendor/images/feature-icon-4.png" alt="" className='img-fluid' />
                                    </div>
                                    <div className="card_content mt-2">
                                        <h5 className='pt-3'> Reliable & Low Cost</h5>
                                        <p className='text-muted mt-3'>In conclusion, Crypto Future trading can be a legitimate and profitable form of investment, but it is important to be aware of the potential for scams..</p>
                                    </div>
                                </div>
                                <div className="card_hover_div">
                                    In conclusion, Crypto Future trading can be a legitimate and profitable form of investment, but it is important to be aware of the potential for scams.
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card_icn">
                                        <img src="https://future-trade.io/vendor/images/feature-icon-5.png" alt="" className='img-fluid' />
                                    </div>
                                    <div className="card_content mt-2">
                                        <h5 className='pt-3'> Insurence Protection</h5>
                                        <p className='text-muted mt-3'>Once negotiations are completed, Foreign Exchange Contract Insurance allows the company to set the forward rate of the currency in order to stabilise proceeds in that currency.</p>
                                    </div>
                                </div>
                                <div className="card_hover_div">
                                    Once negotiations are completed, Foreign Exchange Contract Insurance allows the company to set the forward rate of the currency in order to stabilise proceeds in that currency.
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card_icn">
                                        <img src="https://future-trade.io/vendor/images/feature-icon-6.png" alt="" className='img-fluid' />
                                    </div>
                                    <div className="card_content mt-2">
                                        <h5 className='pt-3'>Experts Support</h5>
                                        <p className='text-muted mt-3'>A Crypto Future expert advisor is an automated system, which monitors the Crypto Future markets and provides signals upon identifying trading opportunities.</p>
                                    </div>
                                </div>
                                <div className="card_hover_div">
                                    A Crypto Future expert advisor is an automated system, which monitors the Crypto Future markets and provides signals upon identifying trading opportunities.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs" data-aos="zoom-in-left">
                <div className="container">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div><span className="primary_cl">FAQS</span></div>
                        <h1 className='fw-bold'>Frequently Asked questions</h1>
                        <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.
                        </p>
                    </div>
                    <div className="row mt-2">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-2">
                            <img src="https://future-trade.io/vendor/images/faq_img002.png" alt="" className='img-fluid' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-2">
                            <div className="tabs">
                                <ul class="nav nav-tabs">
                                    <li class="nav-item">
                                        <a class="nav-link tab_links active" href="#onetab" data-bs-toggle="tab">Genral</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#twotab" data-bs-toggle="tab">Pre ICO & ICO</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#threetab" data-bs-toggle="tab">Token</a>
                                    </li>
                                </ul>


                                <div className='tab-content' id="mytab">
                                    <div className="tab tab-pane" id='onetab'>
                                        <div id="accordion" className='accordion'>
                                            <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collap1">
                                                What is Crypto Future Trading?
                                                <FaAngleDown />
                                            </button>
                                            <div className="accordion_content collapse" id='collap1'>
                                                <p className="text-muted">Crypto Future trading, also known as foreign exchange trading or currency trading, involves the buying and selling of currencies on the foreign exchange market with the aim of making a profit. The Crypto Future market is the
                                                    largest and most liquid financial market globally, where currencies are traded 24 hours a day, five days a week.
                                                </p>
                                            </div>


                                            <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collap2">
                                                How can I participate in the Crypto Future trade sale?
                                                <FaAngleDown />
                                            </button>
                                            <div className="accordion_content collapse" id='collap2'>
                                                <p className="text-muted">
                                                    The foreign exchange (Crypto Future or FX) market is a global marketplace for exchanging national currencies. Because of the worldwide reach of trade, commerce, and finance, Crypto Future markets tend to be the world's largest and most liquid asset markets. Currencies
                                                    trade against each other as exchange rate pairs.

                                                </p>
                                            </div>


                                            <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collap3">
                                                How do I benefit from the ICO Token?
                                                <FaAngleDown />
                                            </button>
                                            <div className="accordion_content collapse" id='collap3'>
                                                <p className="text-muted">

                                                    Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                                </p>
                                            </div>







                                        </div>
                                    </div>

                                    <div className="tab-pane" id='twotab'>
                                        <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab4">
                                            How do I benefit from the ICO Token?
                                            <FaAngleDown />
                                        </button>
                                        <div className="accordion_content collapse" id='collaptab4'>
                                            <p className="text-muted">
                                                Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                            </p>
                                        </div>

                                        <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab5">
                                            What is Ico Trading?
                                            <FaAngleDown />

                                        </button>
                                        <div className="accordion_content collapse" id='collaptab5'>
                                            <p className="text-muted">

                                                Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                            </p>
                                        </div>

                                        <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab6">
                                            How can I participate in the ICO Token sale?
                                            <FaAngleDown />
                                        </button>
                                        <div className="accordion_content collapse" id='collaptab6'>
                                            <p className="text-muted">

                                                Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                            </p>
                                        </div>













                                    </div>

                                    <div className="tab-pane" id='threetab'>
                                        <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab9">
                                            How can I participate in the ICO Token sale?
                                            <FaAngleDown />
                                        </button>
                                        <div className="accordion_content collapse" id='collaptab9'>
                                            <p className="text-muted">
                                                Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                            </p>
                                        </div>

                                        <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab10">
                                            How do I benefit from the ICO Token?
                                            <FaAngleDown />

                                        </button>
                                        <div className="accordion_content collapse" id='collaptab10'>
                                            <p className="text-muted">

                                                Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                            </p>
                                        </div>

                                        <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab12">
                                            What Tradingcurrencies can I use to purchase?
                                            <FaAngleDown />
                                        </button>
                                        <div className="accordion_content collapse" id='collaptab12'>
                                            <p className="text-muted">

                                                Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                            </p>
                                        </div>

                                        <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab11">
                                            What is Ico Trading?
                                            <FaAngleDown />
                                        </button>
                                        <div className="accordion_content collapse" id='collaptab11'>
                                            <p className="text-muted">

                                                Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                            </p>
                                        </div>













                                    </div>








                                </div>











                            </div>
                        </div>
                    </div>


                </div>
            </section>
        </main>
    </div>
  </>
  )
}

export default Home
