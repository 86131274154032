import React from 'react'
import QRCode from 'react-qr-code'

const Deposit = () => {
    return (
        <>
            <div className="row mt-3">
                <div className="main-qr mt-5 text-center ">
                    <div className="mb-3">
                        <h3 className='scan text-center'>Scan the QR code to get  address</h3>

                    </div>
                    <div className='qr-2'>
                        <QRCode className="w-50" value='QR'></QRCode>
                    </div>
                    <h3 className='scan-2 text-center'>Or</h3>

                    <h3 className='scan-copy text-center mt-2'>34HuwzDnSwxVRNCoyFCpQnRBXV2sVVmGUY</h3>

                    <button class="btn btn-secondary mt-4" type="button">Copy Address</button>
                </div>
            </div>
        </>
    )
}

export default Deposit
