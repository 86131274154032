import React, { useState } from 'react';
import _fetch from '../Config/api';
import { base_url } from '../Config/config';
import toast from 'react-hot-toast';
import { ColorRing } from 'react-loader-spinner';
const Bindapi = () => {
    const [name, setName] = useState('');
    const [api, setApi] = useState('');
    const [secret, setSecret] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const [loadinges, setLoadinges] = useState(false);
    const handleBind = async () => {
        const data = {
            exchangeName: name,
            apiKey: api,
            apiSecret: secret
        }

        setLoading(true);
        try {
            let response = await _fetch(`${base_url}api/user/bind`, 'POST', data, {});

            console.log("response", response);

            if (response.success === true) {
                toast.dismiss()
                toast.success(response.message);
                setName('');
                setApi('');
                setSecret('');
            } else {
                toast.dismiss()
                toast.error(response.message);
            }
        } catch (error) {
            toast.dismiss()
            toast.error();
        } finally {
            setLoading(false);
        }
    };

    const handleUnBind = async () => {
        const data = {
            exchangeName: name,
        }
        setLoadings(true);

        try {
            let response = await _fetch(`${base_url}api/user/unbind`, 'POST', data, {});

            console.log("response", response);

            if (response.success === true) {
                toast.dismiss()
                toast.success(response.message);
                setName('');
                setApi('');
                setSecret('');
            } else {
                toast.dismiss()
                toast.error(response.message);
            }
        } catch (error) {
            toast.dismiss()
            toast.error();
        } finally {
            setLoadings(false);
        }
    };


    const handleUpdate = async () => {
        const data = {
            exchangeName: name,
            apiKey: api,
            apiSecret: secret
        }
        setLoadinges(true);
        try {
            let response = await _fetch(`${base_url}api/user/updateBind`, 'POST', data, {});

            console.log("response", response);

            if (response.success === true) {
                toast.dismiss()
                toast.success(response.message);
                setName('');
                setApi('');
                setSecret('');
            } else {
                toast.dismiss()
                toast.error(response.message);
            }
        } catch (error) {
            toast.dismiss()
            toast.error();
        } finally {
            setLoadinges(false);
        }
    };


    return (
        <>
            <div className="row justify-content-center p-3">
                <div className="col-6 mt-5 w-full">
                    <div className="bind_details mt-2">
                        <div className="rounded  p-2">
                            <h3 className='scan text-center'>API Binding</h3>
                            <div className="col mt-5">
                                <div className="heading">
                                    <h5>Precautions</h5>
                                </div>
                                <div className="heading-data">
                                    <p><span>1.</span>Please confirm that the API permission has checked Enable Spot And Margin Trading</p>
                                    <p><span>2.</span>Please enter the correct API, please do not enter special characters</p>
                                </div>
                            </div>
                        </div>
                        <div className="rounded  p-2 mt-3">
                            <div className="heading">
                                <h5>Ip Group binding</h5>
                                <p>For security, Binance Exchange recommends binding the server IP address when creating the API. For users
                                    who need to bind the IP address, click Edit permissions in the upper right corner after the API is created, and click in the IP address permission column to restrict access to only trusted IPs.</p>
                                <span>(Recommended) option, click the copy button to copy the IP group to the input box and click OK, after adding, click save in the upper right corner.</span>
                            </div>
                            <div className="form mb-4 mt-3">
                                <input type="text" className="form-control input" placeholder='165.232.181.116' />
                                <i class="bi bi-copy"></i>

                            </div>
                        </div>
                        <div className="rounded  p-2 mt-3">
                            <div className="mb-3 row">
                                <label htmlFor="exchange_key" className="col-sm-2 col-form-label">Name </label>
                                <div className="col-sm-10">
                                    <select

                                        className="form-control input form-select"
                                        aria-label="Default select example"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    >
                                        <option value="">Exchange Name</option>

                                        <option value="BINANCE">binance</option>
                                    </select>

                                </div>
                            </div>
                            <div className="mb-2 row">
                                <label htmlFor="api_key" className="col-sm-2 col-form-label">Api Key</label>
                                <div className="col-sm-10">
                                    <input
                                        type="text"
                                        className="form-control input"
                                        placeholder='Key'
                                        value={api}
                                        onChange={(e) => setApi(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="mb-2 row">
                                <label htmlFor="input_Password" className="col-sm-2 col-form-label">Secret Key</label>
                                <div className="col-sm-10">
                                    <input
                                        type="password"
                                        className="form-control input"
                                        id="inputPassword"
                                        placeholder='*************'
                                        value={secret}
                                        onChange={(e) => setSecret(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 gap-1">
                            <button className="btn btn-secondary" type="button" onClick={handleBind}>

                                {loading ? (
                                    <ColorRing
                                        height={20}
                                        width={20}
                                        colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']}
                                        ariaLabel="loading"
                                    />
                                ) : (
                                    'Bind'
                                )}
                            </button>
                            <button className="btn btn-secondary" type="button" onClick={handleUnBind}>

                                {loadings ? (
                                    <ColorRing
                                        height={20}
                                        width={20}
                                        colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']}
                                        ariaLabel="loading"
                                    />
                                ) : (
                                    '  Unbind'
                                )}

                            </button>
                            <button className="btn btn-secondary" type="button" onClick={handleUpdate}>

                                {loadinges ? (
                                    <ColorRing
                                        height={20}
                                        width={20}
                                        colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']}
                                        ariaLabel="loading"
                                    />
                                ) : (
                                    ' Update'
                                )}

                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Bindapi;
