import React from 'react'
import { NavLink, useNavigate,useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import _fetch from '../Config/api'
import { base_url } from '../Config/config'
import { useState,useEffect } from 'react'
import { ColorRing } from 'react-loader-spinner';
function Verfication_forget() {
    const { state } = useLocation();
    const [email, setEmail] = useState(state?.email || "");
   
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
   
    const handleforget = async (e) => {
        e.preventDefault();
        if (!email) {
            toast.dismiss();
            toast.error("Please enter the details");
            return;
        }
        setLoading(true); 
        try {
            const forgetData = {
                email: email,
                type:"Forgetotp"
               
            };
            let response = await _fetch(`${base_url}api/user/resendOtp`, 'POST', forgetData, {});

            if (response.success === true) {
                toast.success(response.message);
                // navigate('/Forget', { state: { email: email } });
                navigate('/Forget', { state: { email: email } });
            } else {
                toast.error(response.message);
            }
        }
        catch (error) {
            console.log("Error details", error);
        }finally {
            setLoading(false); 
        }
    }
    return (
        <>
            <div className="conatiner-fluid io">
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="sign-page ls ">
                    <div className="heading text-center  ">
                            <h3 className='H_3'>Email Confirmation</h3>
                        </div>
                        <form >
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Email </label>
                                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete='off' className="form-control" aria-describedby="emailHelp" />
                            </div>

                           
                            
                            <button type="submit" className="btn btn-primary m"  onClick={handleforget}>
                               
                                {loading ? (
                                    <ColorRing
                                        height={20}
                                        width={20}
                                        color="#fff"
                                        ariaLabel="loading"
                                    />
                                ) : (
                                    'resend otp'
                                )}
                                </button>
                            <div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Verfication_forget
