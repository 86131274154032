import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import _fetch from '../Config/api'
import { base_url } from '../Config/config'
import moment from 'moment';
import { useNavigate } from 'react-router';
import LogoutModal from '../Auth/LogoutModal';

const Profile = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [name, setName] = useState({});
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const handledashboard = async () => {
    try {
      let response = await _fetch(`${base_url}api/user/dashboard`, 'get', {});

      if (response.success === true) {
        setData(response?.data?.balance);
        setName(response?.data.user);
      } else {
        toast.dismiss()
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }
  const logout = () => {
    localStorage.clear('token');
    navigate('/');
  };

  useEffect(() => {
    handledashboard();
  }, []);
  return (
    <>
      <div className="container mt-1">
        <div className='row text-h2 m-auto'>

          <h1 className='text-center ' style={{ marginTop: '50px' }}></h1>
          <h3 class="scan text-center">Profile</h3>
          <form>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">Name</label>
              <input type="text" class="form-control" id="exampleInputEmail1" value={name?.name || "N/A"} aria-describedby="emailHelp" />
              {/* <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> */}
            </div>
            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label">User Id</label>
              <input type="text" class="form-control" id="exampleInputPassword1" value={name?.userId || "N/A"} />
            </div>
            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label">Refferals</label>
              <input type="text" class="form-control" id="exampleInputPassword1" value={4} />
            </div>
            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label">Sponser</label>
              <input type="text" class="form-control" id="exampleInputPassword1" value={'IH9879'} />
            </div>
            <div class="mb-3">
             <button className='btn btn-danger ' onClick={() => setIsLogoutModalOpen(true)} type='button'>LOGOUT</button>
            </div>


          </form>
        </div>
      </div>

      <LogoutModal
          isOpen={isLogoutModalOpen}
          onCancel={() => setIsLogoutModalOpen(false)}
          onConfirm={() => {
              setIsLogoutModalOpen(false);
              logout();
          }}
      />

    </>
  )
}

export default Profile
