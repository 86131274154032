import React, { useEffect, useState } from 'react'
import icon from "../assets/img/icon.svg"
import toast from 'react-hot-toast'
import _fetch from '../Config/api'
import { base_url } from '../Config/config'
import plug from '../../src/assets/img/renewable-energy.gif'
import profit from '../../src/assets/img/profit.gif'
import activity from '../../src/assets/img/activity.gif'
import unscreen from '../assets/img/unscreen.gif'
import moment from 'moment';
import trading from '../assets/img/trading.gif'
import cropunscreen from '../assets/img/cropunscreen.gif'
import spot from '../assets/img/spot.gif'
import spottrade from '../assets/img/spottrade.gif'
import { NavLink } from 'react-router-dom'
import businessman from '../assets/img/businessman.jpg'
import stockmarket from '../assets/img/stockmarket.jpg'

const Dashboard = () => {
  const [data, setData] = useState({});
  const [name, setName] = useState({});

  const handledashboard = async () => {
    try {
      let response = await _fetch(`${base_url}api/user/dashboard`, 'get', {});
      console.log('resp is ' ,response)
      if (response.success == true) {
        setData(response?.data?.balance);
        setName(response?.data.user);
      } else {
        toast.dismiss()
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }

  useEffect(() => {
    handledashboard();
  }, []);

  console.log('data', data);

  return (
    <>
      <div className="container mt-5">
        <div id="carouselExampleCaptions" className="carousel slide phonepe-carousel" data-bs-ride="carousel">
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-label="Slide 1" aria-current="true"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2" className=""></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="image_border" src={businessman} alt="..." />
              <div className="carousel-caption d-md-block">
                <h5> </h5>
              </div>
            </div>
            <div className="carousel-item">
              <img className="image_border" src={stockmarket} alt="..." />
              <div className="carousel-caption d-md-block">
                <h5>  </h5>
              </div>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div className="container mt-1">
        <div className='row text-h2 m-auto'>
          <div className='main-boz'>
            <div className='col'>
              <h6>UserId <b>{name?.userId || "N/A"}</b></h6>
              <h6>Name <b>{name?.name || "N/A"}</b></h6>
              <h6>RANK <b>---</b></h6>
              <h6>Fuel Wallet <b>---</b></h6>
              <h6>RegisterAt </h6>
            </div>
            <div className='col'>
              <h6 className='text-end' >Sponser <b>---</b></h6>
              <h6 className='text-end' >Refferals <b>-</b></h6>
              <h6 className='text-end' >Team <b>--</b></h6>
              <h6 className='text-end' >Total Bonus <b>--</b></h6>
              <h6 className='text-end' > <b><b>{moment(name?.createdAt).format("YY-MM-DD hh:mm:ss")} </b></b></h6>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-1">
        <h3 className='marque'><marquee>Congratulations Trades Goal Portal Launched on the Special Occasion of Dusshera </marquee></h3>
      </div>
      <div class="container mt-1">
        <div class="">
          <div class="col-xl-12">
            <div class="main-btn">
              {/* <h2 class="Money-transfer">Transfer Money</h2> */}
              <ul>
                <li class="btnn_blue">
                  <div class="image_border d-flex justify-content-center text-center">
                    <NavLink to="/bindapi" className="inner-btn">
                      <img className="to-mobile" src={plug} alt="" />
                    </NavLink>
                  </div>
                  <NavLink to="/bindapi" className="inner-btn">
                    <span className="text-light text-center">Api Bind</span>
                  </NavLink>
                </li>
                <li class="btnn_blue">
                  <div class="image_border d-flex justify-content-center text-center">
                    <NavLink to="/activity" className="inner-btn">
                      <img src={activity} alt="" className="img-fluid" />
                    </NavLink>
                  </div>
                  <NavLink to="/activity" className="inner-btn">
                    <span className="text-light text-center">Activites</span>
                  </NavLink>
                </li>
                <li class="btnn_blue">
                  <div class="image_border d-flex justify-content-center text-center">
                    <a href="#" class="inner-btn">
                      <img src={unscreen} alt="" class="img-fluid" />
                    </a>
                  </div>
                  <a href="#" class="inner-btn">
                    <span class="text-light text-center">Fuel Deposit</span>
                  </a>
                </li>
                <li class="btnn_blue">
                  <div class="image_border d-flex justify-content-center text-center">
                    <NavLink to="/open_position" class="inner-btn">
                      <img src={trading} alt="" class="img-fluid" />
                    </NavLink>
                  </div>
                  <NavLink to="/open_position" class="inner-btn">
                    <span class="text-light text-center">Trades</span>
                  </NavLink>
                </li>
              </ul>
              <ul>
                <li class="btnn_blue">
                  <div class="image_border d-flex justify-content-center text-center">
                    <NavLink to="/close_position" className="inner-btn">
                      <img className="to-mobile" src={spottrade} alt="" />
                    </NavLink>
                  </div>
                  <NavLink to="/close_position" className="inner-btn">
                    <span className="text-light text-center">position</span>
                  </NavLink>
                </li>
                <li class="btnn_blue">
                  <div class="image_border d-flex justify-content-center text-center">
                    <a href='#' class="inner-btn">
                      <img src={profit} alt="" class="img-fluid" />
                    </a>
                  </div>
                  <a href="#" class="inner-btn">
                    <span class="text-light text-center">Bot Profit</span>
                  </a>
                </li>
                <li class="btnn_blue">
                  <div class="image_border d-flex justify-content-center text-center">
                    <NavLink to="/openspotposition" className="inner-btn">
                      <img src={spot} alt="" class="img-fluid" />
                    </NavLink>
                  </div>
                  <NavLink to="/openspotposition" className="inner-btn">
                    <span className="text-light text-center">Spot Trade</span>
                  </NavLink>
                </li>
                <li class="btnn_blue">
                  <div class="image_border d-flex justify-content-center text-center">
                    <NavLink to="/exchagedkey" class="inner-btn">
                      <img src={cropunscreen} alt="" class="img-fluid" />
                    </NavLink>
                  </div>
                  <NavLink to="/exchagedkey" class="inner-btn">
                    <span class="text-light text-center">Key</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default Dashboard;
